<template ref="empresas">
  <v-container grid-list-sm>
    <div class="d-flex mb-6">
      <p class="title ma-0">Cadastro de empresas da campanha</p>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            icon
            :href="'https://www.youtube.com/watch?v=LLgSbJH8vmI'"
            target="_blank"
          >
            <v-icon class="ml-2">mdi-help-circle-outline</v-icon>
          </v-btn>
        </template>
        <span>Ajuda</span>
      </v-tooltip>
    </div>
    <v-row>
      <v-col>
        <h4 class="mb-2">
          <v-icon class="mr-1" color="red">mdi-close</v-icon>Empresas Fora da
          Campanha
        </h4>
        <v-btn
          class="mb-3"
          color="success"
          @click="addTodasEmpresas"
          :disabled="empresasNaoParticipantes.length == 0"
        >
          Adicionar todas
        </v-btn>
        <draggable
          class="draggable-area exclude-companies"
          :list="empresasNaoParticipantes"
          group="empresas"
          @change="moveAdded"
        >
          <div
            class="v-list-item__content list"
            v-for="empresa in empresasNaoParticipantes"
            :key="empresa.cnpj"
          >
            <div class="list-empresa-items">
              <div class="list-empresa">
                {{
                  `${empresa.apelido} - ${formatNomeEmpresa(
                    empresa.nome_empresa
                  )}`
                }}
                <BaseSegmento :segmento="empresa.id_band" />
              </div>
              <div class="mr-4">
                <v-btn icon @click="addEmpresasParcitipantes(empresa)">
                  <v-icon color="green">mdi-arrow-right-bold</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
        </draggable>
      </v-col>

      <v-divider vertical></v-divider>

      <v-col>
        <h4 class="mb-2">
          <v-icon class="mr-1" color="green">mdi-check</v-icon>Empresas
          Participantes da Campanha
        </h4>
        <v-btn
          class="mb-3"
          color="error"
          @click="removerTodasEmpresas"
          :disabled="empresasParticipantes.length == 0"
        >
          Remover todas
        </v-btn>
        <draggable
          class="draggable-area include-companies"
          :list="empresasParticipantes"
          group="empresas"
          @change="moveRemove"
        >
          <div
            class="v-list-item__content"
            v-for="empresa in empresasParticipantes"
            :key="empresa.id_empresa_campanha"
          >
            <div class="list-empresa-participantes-items">
              <div>
                <v-btn
                  class="mr-4"
                  icon
                  @click="removeEmpresasParticipantes(empresa)"
                >
                  <v-icon color="red">mdi-arrow-left-bold</v-icon>
                </v-btn>
              </div>
              <div class="list-empresa mr-4">
                {{
                  `${empresa.apelido} - ${formatNomeEmpresa(
                    empresa.nome_empresa
                  )}`
                }}
                <BaseSegmento :segmento="empresa.id_band" />
              </div>
            </div>
          </div>
        </draggable>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import serviceCampanha from "@/services/campanha";
import draggable from "vuedraggable";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "DraggableEmpresasCampanha",
  components: {
    draggable,
    BaseSegmento: () => import("@/components/shared/BaseSegmento.vue")
  },

  data() {
    return {
      empresasNaoParticipantes: [],
      empresasParticipantes: []
    };
  },

  computed: {
    ...mapGetters({
      getEmpresasNaoParticipantes: "campanha/getEmpresasNaoParticipantes",
      getEmpresasParticipantes: "campanha/getEmpresasParticipantes"
    })
  },

  methods: {
    ...mapActions({
      actionEmpresasParticipantes: "campanha/actionEmpresasParticipantes"
    }),

    formatNomeEmpresa(item) {
      item = item.split("-")[0];
      return item;
    },

    async inicialize() {
      await this.actionEmpresasParticipantes(this.$route.params.idCampanha);
      this.empresasNaoParticipantes = this.getEmpresasNaoParticipantes;
      this.empresasParticipantes = this.getEmpresasParticipantes;
    },

    async addTodasEmpresas() {
      await Promise.all(
        this.empresasNaoParticipantes.map(item =>
          serviceCampanha.postEmpresasPartipantes({
            id_campanha: this.$route.params.idCampanha,
            id_empresa: item.id_empresa
          })
        ),

        this.$notify({
          type: "success",
          text: `Empresas adicionadas com sucesso`
        })
      ).catch(erro => {
        console.log(erro);
        this.$notify({
          type: "error",
          text: `Erro ao adicionar empresas`
        });
      });
      this.inicialize();
    },

    async removerTodasEmpresas() {
      await Promise.all(
        this.empresasParticipantes.map(item =>
          serviceCampanha.deleteEmpresasParticipantes(item.id_empresa_campanha)
        ),

        this.$notify({
          type: "warning",
          text: `Empresas removidas da campanha`
        })
      ).catch(erro => {
        console.log(erro);
        this.$notify({
          type: "error",
          text: ` Erro ao remover empresas`
        });
      });
      this.inicialize();
    },

    async addEmpresasParcitipantes(item) {
      try {
        const form = {
          id_campanha: this.$route.params.idCampanha,
          id_empresa: item.id_empresa
        };
        await serviceCampanha.postEmpresasPartipantes(form);
        this.$notify({
          type: "success",
          text: `${item.apelido} adicionado na campanha`
        });
      } catch (error) {
        this.$notify({
          type: "error",
          text: ` Erro ao adicionar  ${error.item.apelido}`
        });
      } finally {
        this.inicialize();
      }
    },

    async removeEmpresasParticipantes(item) {
      try {
        await serviceCampanha.deleteEmpresasParticipantes(
          item.id_empresa_campanha
        );
        this.$notify({
          type: "warning",
          text: `${item.apelido} removida da campanha`
        });
      } catch (error) {
        if (error.response.status == 403) {
          return this.$notify({
            type: "error",
            text: error.response.data.policy
          });
        }
        this.$notify({
          type: "error",
          text: ` Error ao remove`
        });
      } finally {
        this.inicialize();
      }
    },

    moveAdded(evt) {
      let empresa = {};
      const { removed } = evt;

      if (removed) {
        empresa = removed.element;
        this.addEmpresasParcitipantes(empresa);
      }
    },

    moveRemove(evt) {
      let empresa = {};
      const { removed } = evt;

      if (removed) {
        empresa = removed.element;
        this.removeEmpresasParticipantes(empresa);
      }
    }
  },

  async mounted() {
    await this.inicialize();
  }
};
</script>

<style scoped>
.v-list-item__content {
  padding-inline-start: 15px;
  border: 1px solid hsla(0, 0%, 31%, 0.199);
  border-radius: 10px;
  margin-bottom: 4px;
}

.draggable-area {
  min-height: 300px;
  padding: 8px;
}

.draggable-area.include-companies {
  border: 2px dashed hsla(128, 100%, 34%, 0.39);
  height: 55vh;
  overflow: auto;
}

.draggable-area.include-companies::-webkit-scrollbar-track {
  background-color: #adf3af;
}
.draggable-area.include-companies::-webkit-scrollbar {
  width: 6px;
}
.draggable-area.include-companies::-webkit-scrollbar-thumb {
  background: #2a772c;
}

.draggable-area.exclude-companies {
  border: 2px dashed hsla(347, 100%, 34%, 0.39);
  height: 55vh;
  overflow: auto;
}

.draggable-area.exclude-companies::-webkit-scrollbar-track {
  background-color: #e7b8b8;
}
.draggable-area.exclude-companies::-webkit-scrollbar {
  width: 6px;
}
.draggable-area.exclude-companies::-webkit-scrollbar-thumb {
  background: #9b3232;
}

.v-list-item__content .list {
  display: flex !important;
  justify-content: space-between;
}

.list-empresa-items {
  display: flex !important;
  justify-content: space-between;
}

.list-empresa-participantes-items {
  display: flex !important;
}

.list-empresa {
  display: flex !important;
  flex-direction: column;
}
</style>
